import { CustomerEngagement, ICustomerEnagement } from "../customerEngagement";
import { ForexCardInfo, IForexCardInfo } from "../forexCardInfo";

import {
  LandingGreySec,
  LandingGreySecWrap,
  LandingTrans,
} from "./ForexCardSection.style";

interface IForexCardSection {
  forexCardEngagement: ICustomerEnagement;
  forexCardInfo: IForexCardInfo;
  events: any;
}
const ForexCardSection = ({
  forexCardEngagement,
  forexCardInfo,
  events,
}: IForexCardSection) => {
  return (
    <LandingGreySec id="grey_ref">
      <LandingGreySecWrap>
        <LandingTrans>
          <CustomerEngagement
            {...forexCardEngagement}
            events={events}
            offering="INR"
          />
          <ForexCardInfo {...forexCardInfo} />
        </LandingTrans>
      </LandingGreySecWrap>
    </LandingGreySec>
  );
};

export default ForexCardSection;
