import styled from "styled-components";

export const LandingTrans = styled.section`
  display: flex;
  margin-bottom: 90px;
  position: relative;

  & input {
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    margin-right: 8px;
    padding: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }

  & input.disable {
    opacity: 0.3;
    pointer-events: none;
  }

  input.focus {
    outline: none;
    border: 1px solid #008cff !important;
  }

  & input.error {
    border: 1px solid red;
  }

  .bmfCard {
    position: absolute;
    top: 260px;
    right: 30px;
    z-index: 28;
  }
  .forexCard {
    width: 290px;
    height: 181px;
    position: absolute;
    top: 60px;
    right: 30px;
    z-index: 26;
  }
`;

export const LandingGreySecWrap = styled.div`
  width: 1200px;
  margin: 0 auto;
  background: #f5f5f5;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const LandingGreySec = styled.div`
  background: #f5f5f5;
  padding: 105px 0;
  margin-top: -120px;

  @media only screen and (max-width: 768px) {
    padding: 16px;
    margin-top: 80px;
  }
`;
