import { IImage } from "@tm/interfaces";
import { BG_COLOR } from "@tm/utility/theme";
import { useForexCardAnimation } from "@tm/ui/hooks";
import { Flex } from "../../shared-styled-components";
import { BulletList } from "../BulletList";
import { IPoweredby } from "../poweredBy";
import { PoweredByText } from "../poweredBy/index.style";

import {
  LandingPoweredBy,
  LandingTransLeft,
  LandingTransPara,
} from "./index.style";
import { Image } from "../Image";

export interface IForexCardInfo {
  title?: string;
  cardImgs: Array<any>;
  forexCardBenefits?: Array<string>;
  poweredBy?: IPoweredby;
}
export const ForexCardInfo = ({
  title,
  cardImgs,
  forexCardBenefits,
  poweredBy,
}: IForexCardInfo) => {
  useForexCardAnimation();
  return (
    <>
      <Image {...cardImgs[0]} key={0} id="bmf_card" className="bmfCard" />
      <Image {...cardImgs[1]} key={1} id="forex_card" className="forexCard" />

      <LandingTransLeft id="trans_left">
        {title && (
          <LandingTransPara>
            <div dangerouslySetInnerHTML={{ __html: title }}></div>
          </LandingTransPara>
        )}
        <BulletList list={forexCardBenefits} bulletColor={BG_COLOR.black} />
        <Flex>
          <PoweredByText>{poweredBy?.title}</PoweredByText>
          <LandingPoweredBy>
            <Image {...poweredBy?.logos?.[0]} key={0} />
          </LandingPoweredBy>
          <Image {...poweredBy?.logos?.[1]} key={1} />
        </Flex>
      </LandingTransLeft>
    </>
  );
};
