import styled from "styled-components";

export const LandingTransLeft = styled.div`
  background: #fff;
  border-radius: 14px;
  padding: 24px 30px;
  margin-left: 80px;
  flex-shrink: 0;
  opacity: 0;
  width: 350px;
`;

export const LandingTransPara = styled.div`
  font-family: "Avenir";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 277px;
  & .greenText {
    color: #22936a;
    margin-bottom: 8px;
    font-weight: 700;
  }
`;

export const LandingPoweredBy = styled.div`
  margin: 0 10px 0 0;
`;
